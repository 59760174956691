<template>
  <div class="d-flex align-baseline flex-nowrap">
    <document-ref-icon
      :visible="showIcon"
      :icon-name="iconName"
      :mixed="mixed"
    />
    <span>{{description}}</span>
    <span class="ml-2">{{formatCurrency(amount, currency)}}
    </span>
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon')
  },
  computed: {
    description () {
      return this.cache?.doc?.description
    },
    amount () {
      return this.cache?.doc?.amount
    },
    currency () {
      return this.cache?.doc?.currency
    }
  },
  methods: {
  },
  data () {
    return {
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    mixed: Boolean,
    cacheType: Number,
    showIcon: Boolean,
    iconName: String
  }
}
</script>

<style lang="stylus" scoped></style>
